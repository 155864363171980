import {get, http, post} from './request'

export default {

  loadPath: (data, success, failure) => {
    return get(
      data.path,
      response => success(response),
      error => failure(error),
      { params: data.params }
    )
  },

  loadPathPost: (path, payload, success, failure) => {
    return post(
      path,
      payload,
      response => success(response),
      error => failure(error)
    )
  },

  downloadFile: (path, success, failure, params = {}) => {
    return http({
        method: 'get',
        url: path,
        responseType: 'blob',
        params,
      }
    ).then(response => success(response))
    .catch(error => failure(error))
  },
  downloadFilePost: (path, payload, success, failure = {}) => {
    return http({
        method: 'post',
        url: path,
        responseType: 'blob',
        data: payload,
      }
    ).then(response => success(response))
    .catch(error => failure(error))
  },
}
