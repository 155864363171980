import { get, http, post } from './request'
import { utilities } from '@/mixins/js-helpers'

export default {
  downloadStandardReport: (siteId, success, failure) => {
    return http({
        method: 'get',
        url: `/api/site/${siteId}/standardreport`,
        responseType: 'blob'
      }
    ).then(response => success(response))
      .catch(error => failure(error))
  },
  downloadDefectiveReport: (siteId, success, failure) => {
    return http({
        method: 'get',
        url: `/api/site/${siteId}/defectivereport`,
        responseType: 'blob'
      }
    ).then(response => success(response))
      .catch(error => failure(error))
  },
  downloadInspectionReportByDateRange: (payload, success, failure) => {
    return http({
        method: 'get',
        url: `/api/site/${payload.siteId}/inspectionreport`,
        params: payload,
        responseType: 'blob'
      }
    ).then(response => success(response))
      .catch(error => failure(error))
  },
  downloadInspectionReportByDateRangeAsExcel: (payload, success, failure) => {
    return http({
        method: 'get',
        url: `/api/site/${payload.siteId}/inspectionreport/xlsx`,
        params: payload,
        responseType: 'blob'
      }
    ).then(response => success(response))
      .catch(error => failure(error))
  },
  loadSites: (payload, success, failure) => {
    return get(
      payload,
      response => success(response),
      error => failure(error)
    )
  },
  loadSiteList: (success, failure) => {
    return get(
      '/api/sites/list',
      response => success(response),
      error => failure(error)
    )
  },
  searchSite: (search, success, failure) => get(
    `/api/sites/search`, success, failure, { params: search }
  ),
  loadSiteListByCompany: (success, failure) => {
    return get(
        `/api/site-list-by-company`,
        response => success(response),
        error => failure(error)
    )
  },
  loadProgressReportAssets: (siteId, success, failure) => {
    return get(
    `/api/site/${siteId}/asset-types-list`,
    response => success(response),
    error => failure(error)
    )
  },
  loadSiteListGroupedByClient: (payload, success, failure) => {
    return get(
      `/api/users/${payload.userId}/sites/grouped-by-client`,
      success, failure, {
        params: payload.query
      }
    )
  },
  loadClientSiteList: (clientId, success, failure) => {
    return get(
      `/api/client/${clientId}/site-list`,
      response => success(response),
      error => failure(error)
    )
  },
  loadClientFilterSiteList: (clientId, success, failure) => {
    return get(
        `/api/client/${clientId}/filter-site-list`,
        response => success(response),
        error => failure(error)
    )
  },
  loadClientUserSites: (clientId, query, success, failure) => {
    return get(
      `/api/client/${clientId}/user-client-sites`,
      success, failure, {
        params: query
      }
    )
  },
  loadSite: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}`,
      response => success(response),
      error => failure(error)
    )
  },
  loadSiteDataForOverview: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}/data-for-overview`,
      response => success(response),
      error => failure(error)
    )
  },
  loadSiteUsers: (siteId, success, failure) => {
    return get(
      `/api/sites/${siteId}/site-users`,
      response => success(response),
      error => failure(error)
    )
  },
  loadSitesByClient: (path, success, failure) => {
    return get(
      path,
      response => success(response),
      error => failure(error)
    )
  },
  fetchTechnicians: (siteId, success, failure) => {
    return get(
        `/api/site/${siteId}/technicians`,
        response => success(response),
        error => failure(error)
    )
  },
  fetchSiteAdmins: (siteId, success, failure) => {
    return get(
        `/api/site/${siteId}/admins`,
        response => success(response),
        error => failure(error)
    )
  },
  loadSiteForEditing: (siteId, success, failure) => {
    return get(
        `/api/sites/${siteId}/edit`,
        response => success(response),
        error => failure(error)
    )
  },
  createSite: (payload, success, failure) => {
    return post(
      '/api/site',
      payload,
      response => success(response),
      error => failure(error)
    )
  },
  saveSite: (site, success, failure) => {
    return post(
      `/api/site/${site.id}`,
      site,
      response => success(response),
      error => failure(error)
    )
  },
  syncUsers: (siteId, payload, success, failure) => post(
    `/api/site/${siteId}/sync-users`,
    payload, success, failure
  ),
  deleteSite: (siteId, success, failure) => {
    return http.delete(`/api/site/${siteId}`)
      .then(response => success(response))
      .catch(error => failure(error))
  },
  uploadLogo: (payload, success, failure) => {
    return post(
      `/api/site/${payload.site}/logo`,
      utilities.formData({ file: payload.file }),
      response => success(response),
      error => failure(error)
    )
  },
  resetSiteLogo: (payload, success, failure) => {
    return post(
      `/api/site/${payload.site}/logo/reset`,
      {},
      response => success(response),
      error => failure(error)
    )
  },
  loadOverview: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}/overview`,
      response => success(response),
      error => failure(error)
    )
  },
  loadDashboard: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}/dashboard`,
      response => success(response),
      error => failure(error)
    )
  },
  loadAssetHealth: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}/asset-health`,
      response => success(response),
      error => failure(error)
    )
  },
  loadDefectiveHighRiskAssets: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}/recently-defective-assets`,
      response => success(response),
      error => failure(error)
    )
  },
  recoverSite: (siteId, success, failure) => {
    return post(
      `/api/site/${siteId}/recover`,
      {},
      response => success(response),
      error => failure(error)
    )
  },
  mailFailedChecksActivityReport: (siteId, success, failure) => {
    return get(
      `/api/site/${siteId}/failed-checks-activity-report`,
      response => success(response),
      error => failure(error)
    )
  },
  loadDropdownSiteList: (success, failure) => {
    return get(
        '/api/sites/dropdown-list',
        response => success(response),
        error => failure(error)
    )
  },
}
