import {get, http, post} from './request'
import {utilities} from '@/mixins/js-helpers'

export default {
    loadJobs: (path, success, failure) => {
        return get(
            `/api${path}`,
            response => success(response),
            error => failure(error)
        )
    },
    loadGroupedSchedule: (success, failure) => {
        return get(
            `/api/job-schedule`,
            response => success(response),
            error => failure(error)
        )
    },
    loadJobCalendar: (success, failure) => {
        return get(
            `/api/jobs/calendar`,
            response => success(response),
            error => failure(error)
        )
    },
    loadJobManager: (jobId, success, failure) => {
        return get(
            `/api/job/${jobId}/manager`,
            response => success(response),
            error => failure(error)
        )
    },
    store: (job, success, failure) => {
        for (let i = 0; i < job.documents.length; i++) {
            job[`documents[${i}]`] = job.documents[i]
        }

        return post(
            '/api/job',
            utilities.formData(job),
            response => success(response),
            error => failure(error)
        )
    },
    save: (job, success, failure) => {
        return post(
            `/api/job/${job.id}`,
            job,
            response => success(response),
            error => failure(error)
        )
    },
    addZone: (payload, success, failure) => {
        return post(
            `/api/job/${payload.job}/zone`,
            {zone: payload.zone},
            response => success(response),
            error => failure(error)
        )
    },
    getAssetTypesByJob: (payload, success, failure) => {
        return get(
            `/api/jobs/${payload}/asset-types`,
            response => success(response),
            error => failure(error)
        )
    },
    downloadFilteredIndexReport: (exportType, filters, success, failure) => {
        return http({
            method: 'get',
            url: `/api/jobs/report`,
            params: {...filters, exportType},
            responseType: 'blob'
        }).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadReport: (jobId, success, failure) => {
        return http({
                method: 'get',
                url: `/api/jobs/${jobId}/report`,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadSummaryReport: (payload, success, failure) => {
        return http({
                method: 'post',
                url: `/api/jobs/${payload.jobId}/summary-report`,
                data: payload.options,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadInspectionReport: (payload, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/${payload.inspection}/report`,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadJobWithInspectionsReports: (jobId, success, failure) => {
        return http({
                method: 'get',
                url: `/api/jobs/${jobId}/job-with-inspections-reports`,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadJobWithFailedInspectionsReports: (jobId, success, failure) => {
        return http({
                method: 'get',
                url: `/api/jobs/${jobId}/job-with-failed-inspections-reports`,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadInspectionReportAsFile: (payload, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/${payload.inspection}/report/${payload.type}`,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    downloadInspectionEvidence: (payload, success, failure) => {
        return http({
                method: 'get',
                url: `/api/job/${payload.job}/inspection/${payload.inspection}/evidence-zip`,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },
    storeClosingCommentsForJob: (payload, success, failure) => {
        return post(
            `/api/jobs/${payload.jobId}/closing-comments`,
            {'answer': payload.answers},
            response => success(response),
            error => failure(error)
        )
    },
    closeJob: (jobId, success, failure) => {
        return post(
            `/api/job/${jobId}/close`,
            null,
            response => success(response),
            error => failure(error)
        )
    },
    signJob: (data, success, failure) => {
        let payload = {
            name: data.signatureName
        }
        payload['file'] = data.signatureImage
        return post(
            `api/jobs/${data.jobId}/electronic-signature`,
            utilities.formData(payload),
            response => success(response),
            error => failure(error)
        )
    },
    reopenJob: (jobId, success, failure) => {
        return post(
            `/api/job/${jobId}/reopen`,
            null,
            response => success(response),
            error => failure(error)
        )
    },
    loadOverview: (jobId, success, failure) => {
        return get(
            `/api/job/${jobId}/overview`,
            response => success(response),
            error => failure(error)
        )
    },
    deleteJob: (jobId, success, failure) => {
        return post(
            `/api/job/${jobId}/delete`,
            {},
            response => success(response),
            error => failure(error)
        )
    },
    recoverJob: (jobId, success, failure) => {
        return post(
            `/api/job/${jobId}/recover`,
            {},
            response => success(response),
            error => failure(error)
        )
    }
}
